import React from "react"
import "../../assets/styles/main.scss"
import Footer from "./Footer"
import Header from "./Header"

export default function Layout({ children, current }) {

  console.log(current)

  return (
    <>
      
      <Header current={current}/>
      {children}
      <Footer current={current}/>
    </>
  )
}
